import React from 'react';

const ServiceItem = ({ iconPath, title, link }) => {
    return (
        <div className="flex flex-col items-center text-center h-auto">
            <div className="mb-1 flex-4 flex flex-col items-center justify-center">
                <img src={iconPath} alt={`${title} Icon`}
                     className="w-12 h-12 mb-4"/>
                <h4 className="text-slate-200 silver-glow">{title}</h4>
                <a href={link} className="text-cyan-700 brightness-200">> Learn more</a>
            </div>
        </div>
    );
};

export default ServiceItem;
