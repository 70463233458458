import React from 'react';

const Footer = () => {
    return (
        <footer className="bg-black text-white py-8">
            <div className="container mx-auto px-10 grid grid-cols-3 gap-8">
                <div className="flex flex-col items-start">
                    <img src="../assets/logo/Logo_Side_Text_White.png" alt="Lenserio Logo" className="mb-4 w-32"/>
                </div>
                <div className="flex flex-col">
                    <h4 className="font-bold">Company</h4>
                    <a href="#main-page" className="mt-2 text-sm">Home</a>
                    <a href="#about-us-page" className="mt-1 text-sm">About Us</a>
                    <a href="#shoplifting-page" className="mt-1 text-sm">Services</a>
                </div>
                <div className="flex flex-col">
                    <h4 className="font-bold">Contact</h4>
                    <a className="text-sm mt-2 mb-1" href="tel:+40712345678">+40712345678</a>
                    <a className="text-sm break-words" href="mailto:contact@lenserio.com"> contact@lenserio.com</a>
                </div>
            </div>
            <div className="text-center mt-8 border-t border-gray-700 pt-4">
                <p className="italic shadow">Copyright © 2024 Lenserio. All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
