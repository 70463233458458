import React, {useState} from 'react';
import {AiOutlineClose} from 'react-icons/ai';
import {HiOutlineMenuAlt3} from 'react-icons/hi';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const handleNav = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav className="fixed h-[8vh] top-0 left-0 w-full z-50 bg-gradient-to-b from-black to-neutral-800 text-center">
            <div className='flex justify-between items-center h-[8vh] max-w-[1240px] min-w-96 mx-auto px-4 text-white'>
                <div className="flex items-center">
                    <img src="../assets/logo/Logo_NO_TEXT.png" alt="Lenserio Logo" className="h-12 mr-2"/>
                    <img src="../assets/logo/Logo_TEXT_WHITE.png" alt="Lenserio Text" className="h-5 text-blue-500"/>
                </div>
                <ul className='hidden md:flex items-center'>
                    <li className="p-4">
                        <a href="#main-page"
                           className="py-2 text-white hover:text-lightBlue-300 hover:underline underline-offset-8"
                           style={{
                               textDecorationColor: 'white',
                               textDecorationThickness: '0.1em',
                               textDecorationOffset: '0.6em'
                           }}
                        >Home
                        </a>
                    </li>
                    <li className="p-4">
                        <a href="#shoplifting-page"
                           className="py-2 text-white hover:text-lightBlue-300 hover:underline underline-offset-8"
                           style={{
                               textDecorationColor: 'white',
                               textDecorationThickness: '0.1em',
                               textDecorationOffset: '0.6em'
                           }}
                        >Services
                        </a>
                    </li>
                    <li className="p-4">
                        <a href="#about-us-page"
                           className="py-2 text-white hover:text-lightBlue-300 hover:underline underline-offset-8"
                           style={{
                               textDecorationColor: 'white',
                               textDecorationThickness: '0.1em',
                               textDecorationOffset: '0.6em'
                           }}
                        >About Us
                        </a>
                    </li>
                    <li className="p-4">
                        <button
                            className="hidden md:flex justify-center m-auto py-2 px-4 ml-4 bg-white border-white hover:bg-black rounded-full text-black focus:outline-none hover:border-white focus:ring-2 focus:ring-white hover:text-white focus:ring-opacity-50 transition duration-300">
                            Contact Us
                        </button>
                    </li>
                </ul>
                <div onClick={handleNav} className='block md:hidden'>
                    {isOpen ? <AiOutlineClose size={20}/> : <HiOutlineMenuAlt3 size={26}/>}
                </div>
                <ul className={isOpen ? 'fixed left-0 top-[8vh] w-[60%] h-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500 transform translate-x-0' : 'fixed left-0 top-[8vh] w-[60%] h-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500 transform translate-x-[-100%]'}>
                    <li className='p-4 border-b border-gray-600'><a href="#main-page">Home</a></li>
                    <li className='p-4 border-b border-gray-600'><a href="#shoplifting-page">Services</a></li>
                    <li className='p-4 border-b border-gray-600'><a href="#about-us-page">About Us</a></li>
                    <li className="p-4 items-center">
                        <button
                            className="flex justify-center m-auto py-2 px-4 bg-white border-white hover:bg-black rounded-full text-black focus:outline-none hover:border-white focus:ring-2 focus:ring-white hover:text-white focus:ring-opacity-50 transition duration-300">
                            Contact Us
                        </button>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default Navbar;
