import React from 'react';
import Navbar from "./components/Navbar";
import PageContainer from "./components/PageContainer";
import MainPage from "./components/MainPage";
import AboutUsPage from "./components/AboutUsPage";
import Footer from "./components/Footer";
import ShopliftingPage from "./components/ShopliftingPage";
import SuspiciousPage from "./components/SuspiciousPage";
import ViolencePage from "./components/ViolencePage";
import LitteringPage from "./components/LitteringPage";


function App() {
    return (
        <div className="relative w-full min-w-80">
            <Navbar/>
            <div id="main-page" className="mt-[8vh] section"> {/* 8vh is the height of the navbar */}
                <PageContainer>
                    <MainPage/>
                </PageContainer>
            </div>
            <div id="shoplifting-page" className="section">
                <PageContainer>
                    <ShopliftingPage/>
                </PageContainer>
            </div>
            <div id="littering-page" className="section">
                <PageContainer>
                    <LitteringPage/>
                </PageContainer>
            </div>
            <div id="suspicious-page" className="section">
                <PageContainer>
                    <SuspiciousPage/>
                </PageContainer>
            </div>
            <div id="violence-page" className="section">
                <PageContainer>
                    <ViolencePage/>
                </PageContainer>
            </div>
            <div id="about-us-page" className="section">
                <PageContainer>
                    <AboutUsPage/>
                </PageContainer>
            </div>
            <Footer/>
        </div>
    );
}

export default App;
