import React from 'react';
import icon_theft from '../assets/icons/icon-theft.png';
import icon_suspicious from '../assets/icons/icon-suspicious.png';
import icon_violence from '../assets/icons/icon-violence.png';
import icon_littering from '../assets/icons/icon-littering.png';
import background from '../assets/background.png';
import ServiceItem from "./ServiceItem";


function MainPage() {
    const services = [
        { id: 1, title: 'Shoplifting', link: '#shoplifting-page', iconPath: icon_theft },
        { id: 2, title: 'Suspicious', link: '#suspicious-page', iconPath: icon_suspicious },
        { id: 3, title: 'Violence', link: '#violence-page', iconPath: icon_violence },
        { id: 4, title: 'Littering', link: '#littering-page', iconPath: icon_littering }
    ];

    return (
        <div className="h-full">
            {/* Desktop view */}
            <div className="md:flex md:flex-col md:h-full hidden">
                <div className="flex-grow relative">
                    {/* Background image covering the full area of the container */}
                    <img
                        src="../assets/background.png"
                        className="absolute left-0 top-0 w-full h-full object-cover"
                        alt="lenserio-background-logo"
                    />
                    {/* Left Text Div */}
                    <div
                        className="absolute left-4 top-1/2 transform -translate-y-1/2 text-center max-w-96 rounded p-5 xl:ml-36 pt-20 shadow-lg bg-black bg-opacity-50">
                        <h1 className="text-4xl xl:text-6xl text-white font-bold mb-4 silver-glow">Enhance Your Cameras with AI</h1>
                        <p className="text-base xl:text-lg text-white">Upgrade your security cameras with advanced AI.</p>
                    </div>
                    {/* Right Text Div */}
                    <div
                        className="absolute right-4 top-1/2 transform -translate-y-1/2 text-center max-w-96 rounded p-5 xl:mr-36 pb-20 shadow-lg bg-black bg-opacity-50">
                        <h1 className="text-4xl xl:text-6xl text-white font-bold mb-4 silver-glow">Real-Time Security Insights</h1>
                        <p className="text-base xl:text-lg text-white">Our technology analyzes video feeds in real time.</p>
                    </div>
                </div>
                { /* Services Section */}
                <div className="text-white max-w-5xl m-auto mb-20">
                    <div className="container mx-auto grid grid-cols-4 gap-24">
                        {services.map(service => (
                            <ServiceItem
                                key={service.id}
                                iconPath={service.iconPath}
                                title={service.title}
                                link={service.link}
                            />
                        ))}
                    </div>
                </div>
            </div>

            { /* Mobile view */}
            <div className="md:hidden flex flex-col h-full">
                <div className="mx-auto my-4 text-center rounded px-5 shadow-lg">
                    <h1 className="text-2xl text-white font-bold mb-2 silver-glow">Enhance Your Cameras with AI</h1>
                    <p className="text-white">Upgrade your security cameras with advanced AI.</p>
                </div>
                <div style={{backgroundImage: `url(${background})`}}
                     className="h-full bg-no-repeat bg-center bg-cover"/>
                <div className="mx-auto text-center rounded px-5 shadow-lg">
                    <h1 className="text-2xl text-white font-bold mb-2 silver-glow">Real-Time Security Insights</h1>
                    <p className="text-white">Our technology analyzes video feeds in real time.</p>
                </div>
                { /* Services Section */}
                <div className="text-white mx-auto my-8">
                    <div className="container mx-auto grid grid-cols-2 grid-rows-2 gap-12">
                        {services.map(service => (
                            <ServiceItem
                                key={service.id}
                                iconPath={service.iconPath}
                                title={service.title}
                                link={service.link}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MainPage;
