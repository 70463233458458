import React from 'react';
import litteringBackground from '../assets/pages/page2-2x.png';
import icon_littering from '../assets/icons/icon-littering.png';


const litteringPage = () => {
    return (
        <div className="relative h-full w-full">
            <div style={{backgroundImage: `url(${litteringBackground})`}}
                 className="h-full bg-no-repeat bg-left"/>
            <div style={{
                clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 50% 100%)',
                backgroundColor: 'rgba(0, 0, 0, 1)'
            }} className="absolute inset-0"></div>
            <img
                src={icon_littering}
                className="absolute right-[30%] md:right-[15%] top-[10%] w-32 p-2 border-2 border-white rounded-full border-dashed bg-black bg-opacity-50"
                alt="lenserio-background-logo"
            />
            <div
                className="absolute m-auto py-8 right-[10%] md:right-[15%] top-[30%] md:top-[35%] text-white text-center bg-black bg-opacity-50 max-w-sm lg:max-w-xl">
                 <h1 className="text-3xl md:text-5xl lg:text-6xl font-bold silver-glow mb-8">Littering
                    Detection Module</h1>
                 <p className="text-base md:text-lg lg:text-2xl mb-12 px-2">Identify and alert on incidents of improper waste disposal,
                    leveraging advanced image recognition technology to maintain cleanliness and order in public and
                    private spaces.</p>
                <button
                    className="flex justify-center m-auto py-2 px-8 text-xl text-black font-bold brightness-200 bg-cyan-700 rounded-full hover:bg-cyan-950 focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-80 transition duration-300">
                    Explore Now
                </button>
            </div>
        </div>
    );
};

export default litteringPage;
