import React from 'react';
import shopliftingBackground from '../assets/pages/page1-2x.png';
import icon_theft from '../assets/icons/icon-theft.png';


const ShopliftingPage = () => {
    return (
        <div className="relative h-full w-full">
            <div style={{backgroundImage: `url(${shopliftingBackground})`}}
                 className="h-full bg-no-repeat bg-right"/>
            <div style={{
                clipPath: 'polygon(0% 100%, 100% 100%, 33% 0%, 0% 0%)',
                backgroundColor: 'rgba(0, 0, 0, 1)'
            }} className="absolute inset-0"/>
            <img
                src={icon_theft}
                className="absolute left-[30%] md:left-[15%] top-[10%] w-32 p-2 border-2 border-white rounded-full border-dashed bg-black bg-opacity-50"
                alt="lenserio-background-logo"
            />
            <div className="absolute m-auto py-8 left-[10%] md:left-[15%] top-[30%] md:top-[35%] text-white text-center bg-black bg-opacity-50 max-w-sm lg:max-w-xl">
                <h1 className="text-3xl md:text-5xl lg:text-6xl font-bold silver-glow mb-8">Shoplifting
                    Detection Module</h1>
                <p className="text-base md:text-lg lg:text-2xl mb-12 px-2">Automatically detect and alert clients to shoplifting incidents
                    in real-time, bolstering theft prevention in supermarkets and retail environments.</p>
                <button
                    className="flex justify-center m-auto py-2 px-8 text-xl text-black font-bold brightness-200 bg-cyan-700 rounded-full hover:bg-cyan-950 focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-80 transition duration-300">
                    Explore Now
                </button>
            </div>
        </div>

    );
};

export default ShopliftingPage;
