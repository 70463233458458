import React from 'react';

const PageContainer = ({children}) => {
    return (
        <div className="h-[92vh] w-full bg-[#030408]">
            {children}
        </div>
    );
}

export default PageContainer;
