import React from 'react';

import alex from '../assets/icons/team/alex.png';
import radu from '../assets/icons/team/radu.png';
import selma from '../assets/icons/team/selma.png';
import vic from '../assets/icons/team/vic.png';
import aboutUsPageBackground from '../assets/pages/page5.png';


const AboutUsPage = () => {
    const team = [
        {name: 'Alex Gheară', imagePath: alex, description: 'BSc Computing Science, \n Fullstack Engineer'},
        {name: 'Radu Gheorghe', imagePath: radu, description: 'MSc Software Engineering, \n Software Engineer'},
        {name: 'Selma Musledin', imagePath: selma, description: 'MSc Artificial Intelligence, \n Data Scientist'},
        {name: 'Victor Florea', imagePath: vic, description: 'BSc Computing Science, \n Product Engineer'}
    ];

    return (
        <div className="relative h-full w-full">
            <div style={{backgroundImage: `url(${aboutUsPageBackground})`}} className="h-full bg-cover bg-no-repeat">
                <div className="h-screen w-full flex items-center justify-center">
                    <div
                        className="w-full h-[60%] md:h-[38%] bg-black bg-opacity-50 flex items-center justify-center px-4 md:px-8">
                        <div
                            className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-2 md:gap-4 lg:gap-6 xl:gap-8 lg:ml-32 xl:ml-64">
                            {team.map((member, index) => (
                                <CircleImage key={index} name={member.name} imagePath={member.imagePath}
                                             description={member.description}/>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div style={{
                clipPath: 'polygon(0% 0%, 33% 0%, 0% 50%)',
                backgroundColor: 'rgba(0, 0, 0, 1)'
            }} className="absolute inset-0"></div>
        </div>

    );
};


const CircleImage = ({imagePath, name, description}) => {
    return (
        <div className="flex flex-col items-center justify-center text-center p-2">
            <div
                className="w-32 h-32 md:w-40 md:h-40 lg:w-48 lg:h-48 bg-white rounded-full overflow-hidden flex items-center justify-center">
                <img src={imagePath} alt={"Image of " + name} className="w-full h-full object-cover"/>
            </div>
            <h3 className="text-white text-sm md:text-md lg:text-lg font-bold mt-3">{name}</h3>
            <h5 className="text-white text-xs md:text-sm lg:text-md">{description}</h5>
        </div>
    );
}

export default AboutUsPage;
